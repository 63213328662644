<template slot-scope="scope">  
   <div class="media">
    <div class="middle5" id="aaa">
      <div class="article-card-box-min">
        <ul class="clearfix">
          <li class="article-card">
            <a href="/article/612.html" target="_blank" class="figure"><img class="course-img-bg lazy-img" data-original="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_03251J111P09.jpg" title="什么叫HTTP?" src="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_03251J111P09.jpg" style="display: block;"></a>
            <div class="article-text">
            <h2 @click="look" class="article-title">什么叫HTTP?</h2>
            <p class="desc">1.什么叫HTTP? 我们绝大多数的Web应用都是基于HTTP来进行开发的。我们对Web的操作都是通过HTTP协议来进行传输数据的。简单来说，HTTP协议就是客户端和服务器交互的一种通...</p>
            <div class="info">
            <span class="time">03-25</span>
            <span class="read">90人看过</span>
            </div>
            </div>
          </li>
          <li class="article-card">
            <a href="/article/611.html" target="_blank" class="figure"><img class="course-img-bg lazy-img" data-original="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_03251F6146149.png" title="node commonJS语法" src="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_03251F6146149.png" style="display: block;"></a>
            <div class="article-text">
              <h2 @click="look" class="article-title">node commonJS语法</h2>
            <p class="desc">本文章向大家介绍node commonJS语法，主要包括node commonJS语法使用实例、应用技巧、基本知识点总结和需要注意事项，具有一定的参考价值，需要的朋友可以参考一下。 （一...</p>
            <div class="info">
            <span class="time">03-25</span>
            <span class="read">222人看过</span>
            </div>
            </div>
            </li>
            <li class="article-card">
              <a href="/article/569.html" target="_blank" class="figure"><img class="course-img-bg lazy-img" data-original="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032514153J523.png" title="服务器+ Nginx 搭建静态资源 web 服务器" src="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032514153J523.png" style="display: block;"></a>
              <div class="article-text">
                <h2 @click="look" class="article-title">服务器+ Nginx 搭建静态资源 web 服务器</h2>
              <p class="desc">在搭建网站的时候，往往会加载很多的图片，如果都从 Tomcat 服务器来获取静态资源，这样会增加服务器的负载，使得服务器运行 速度非常慢，这时可以使用 Nginx 服务器...</p>
              <div class="info">
              <span class="time">03-25</span>
              <span class="read">174人看过</span>
              </div>
              </div>
            </li>
            <li class="article-card">
              <a href="/article/550.html" target="_blank" class="figure"><img class="course-img-bg lazy-img" data-original="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032511035O503.png" title="深入了解Python数据处理及可视化" src="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032511035O503.png" style="display: block;"></a>
              <div class="article-text">
                <h2 @click="look" class="article-title">深入了解Python数据处理及可视化</h2>
              <p class="desc">一、NumPy的初步使用 表格是数据的一般表示形式，但对于机器来说是不可理解的，也就是无法辨识的数据，所以我们需要对表格的形式进行调整。 常用的机器学习表示形...</p>
              <div class="info">
              <span class="time">03-25</span>
              <span class="read">172人看过</span>
              </div>
              </div>
            </li>
            <li class="article-card">
              <a href="/article/548.html" target="_blank" class="figure"><img class="course-img-bg lazy-img" data-original="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032510535445c.png" title="浅析vue中的生命周期钩子mounted" src="http://bbs.tianzhuobj.com/uploads/allimg/2203/1_032510535445c.png" style="display: block;"></a>
              <div class="article-text">
                <h2 @click="look" class="article-title">浅析vue中的生命周期钩子mounted</h2>
              <p class="desc">聊之前我们先看一个场景 div id=app aC / bC //div 如上所示，App.vue文件里面有两个子组件，互为兄弟关系 组件里面自各有created和mounted两个生命周期钩子，a表示组件名 C是cr...</p>
              <div class="info">
              <span class="time">03-25</span>
              <span class="read">223人看过</span>
              </div>
              </div>
            </li>
        </ul>

      </div>
      <div class="star-article-r">
        <div class="article-hot-l">
          <img src="../assets/ewm.png" class="img8">
        </div>
        </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="13%"
      center>
          扫码付费可查看全部内容
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="centerDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
      <!-- 底部内容 -->
      <div class="footer">
        <vbottom></vbottom>
      </div>
   </div>
</template>

<script>
import vbottom from "../components/bottom";
export default {
  data(){
        return{
            index:1,    //默认设为1，因为我的图片没有0.jpg，所以如果设置为0为报错
            dialogWidth: "",    //弹窗宽度，可根据图片宽度进行改变
            boxWidth: "",    //图片宽度
            centerDialogVisible: false,
            //其他数据里包含id值，这个可以自己根据数据来调整
        }
    },
    methods:{
        // 通过图片宽度来改变容器宽度，做到自适应，但是有待改善的地方。如果图片大小不一样.
        //不刷新页面的情况下，点击了宽度较小的图片后再点击宽度较大的图片，宽度会按照小的图片的宽度来显示。
        onLoadImg: function (e) {
            var img = e.target;
            var width = 0;
            if (img.fileSize > 0 || (img.width > 1 && img.height > 1)) {
                width = img.width;
            }
            // if ((img.height > img.width) &&  width > 370) {
            //     width = 370
            // } else if (width > 600) {
            //     width = 600
            // }
            this.boxWidth= width + 'px';
            this.dialogWidth = width + 40 + 'px';
        },
        look(){
            this.centerDialogVisible = true
        },
//获取id值，并且将其赋值给index，从而获取对应的图片名字
getrows(row){
            console.log(row.id)
            this.index=row.id
        },
    },
    components: { vbottom },

}
</script>
<style scoped>
.article-card-box-min {
    width: 835px;
    min-height: 550px;
    float: left;
}
dl, ol, ul, li {
    list-style: none;
}
.article-card {
    overflow: hidden;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
}
.article-text {
    width: 540px;
    height: 131px;
    float: left;
    margin-left: 25px;
}
.article-card h2 {
    margin-bottom: 15px;
    padding-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.article-card .article-title {
    color: #333;
    font-size: 20px;
}
.article-card .desc {
    height: 45px;
    font-size: 12px;
    line-height: 22px;
    color: #999;
}
.article-card .info {
    color: #999;
    font-size: 12px;
    padding-top: 15px;
}
.article-card .info .time {
    color: #666;
    padding-right: 10px;
}
.article-card .info .read {
    float: right;
    color: #666;
}
.article-card .figure img {
    width: 240px;
    height: 131px;
    display: block;
    border-radius: 8px;
}
.star-article-r {
    width: 330px;
    float: right;
}
.article-hot-l {
    background: #fff;
    padding: 25px 80px;
    margin-bottom: 20px;
    border-radius: 8px;
}
.media{
  background: #f5f5f5;
}
.middle5 {
  width: 1180px;
  height: 870px;
  margin: auto;
  position: relative;
  padding-top: 20px;
}
.footer{
  width: 100%;
  /* position: fixed;
  bottom: 0; */
}
.title23 {
  width: 258px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top: 110px;
  left: 560px;
}
.line5 {
  width: 40px;
  height: 4px;
  background: #005fff;
  border-radius: 2px;
  position: absolute;
  top: 138px;
  left: 560px;
}
.intro37 {
  width: 720px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 162px;
  left: 460px;
}
.maodian {
  height: 1px;
  position: absolute;
  top: 672px;
}
.ztc {
  width: 100%;
  height: 48px;
  background: #fafafa;
  border-radius: 24px;
  margin-bottom: 20px;
  margin-left: 765px;
  margin:auto;
  cursor: pointer;
}
.ztc2 {
  font-size: 18px;
  font-weight: 400;
  color: #005fff;
  line-height: 18px;
  padding-top: 15px;
  padding-left: 670px;
  max-width:1180px;
}
.dianhua4{
  position: absolute; 
  display: none;
  top: -50px;
  left:15px;
  height:140px;
  width: 1180px;
  margin:auto;
}
.ztc:hover .dianhua4{
  display:block;
 }
.img8{
  height:150px
 }
.img9{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
 .img10{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
 .img11{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
 .img12{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
 .img13{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
 .img14{
  position: absolute; 
  display: none;
  top: 120px;
  left:1035px;
  height:150px
 }
.i1{
  width: 1066px;
  position: absolute; 
  top: 20px;
  height:600px;
} 
.i2{
  width: 1066px;
  position: absolute; 
  top: 640px;
  height:600px;
} 
.i3{
  width: 1066px;
  position: absolute; 
  top: 1260px;
  height:600px;
} 
.i4{
  width: 1066px;
  position: absolute; 
  top: 1880px;
  height:600px;
} 
.i5{
  width: 1066px;
  position: absolute; 
  top: 2400px;
  height:600px;
}
.i6{
  width: 1066px;
  position: absolute; 
  top: 3020px;
  height:600px;
}  
.i7{
  width: 1066px;
  position: absolute; 
  top: 3640px;
  height:600px;
} 
.i1:hover .img8{
  display:block;
}
.i2:hover .img9{
  display:block;
}
.i3:hover .img10{
  display:block;
}
.i4:hover .img11{
  display:block;
}
.i5:hover .img12{
  display:block;
}
.i6:hover .img13{
  display:block;
}
.i7:hover .img14{
  display:block;
}
</style>
