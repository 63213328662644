<template>
  <div class="join">
    <div class="beijingtupian4">
    </div>
    <div class="middle11">
      <h6 class="title1">OFFICE LOCATION</h6>
      <h6 class="title2">办公地点</h6>
      <div class="place">
        <div @click="handleClass('0')" :class="active=='0'?'activeborder':' '">北京-朝阳区</div>
        <div @click="handleClass('1')" :class="active=='1'?'activeborder':' '">杭州</div>
      </div>
    </div>
    <div class="ditu">
      <div style="width:100%;height:540px" id="container"></div>
    </div>
    <div class="middle12">
      <div class="final">
        <img src="../assets/logo.svg" style="margin-top:60px;">
        <div class="line"></div>
        <h6 class="title3">世界这么大，来这里就对了，成功的路上期待与你同行！</h6>
        <div class="ztc" onclick="window.open('https://www.zhipin.com/gongsir/209943ae414015a50n1z3tu7.html?ka=company-jobs','_self')">
          <p class="ztc2">职位直通车</p>
        </div>
        <h6 class="title4">HR: hr@tianzhuobj.com</h6>
      </div>
    </div>
    <div class="lanbian2"></div>
    <!-- 底部内容 -->
    <vbottom></vbottom>
    
  </div>
</template>  
<script>
import dizhiicon from '../assets/dizhiicon.png' 
import dianhuaicon from '../assets/dianhuaicon.png' 
import dizhiqipao from '../assets/dizhiqipao.png' 
import vbottom from "../components/bottom";
export default {
  data() {
    return {
    
      active: "0",
      map: null,
      positionList: {
        bjCy: [[116.500791,39.893685], "北京市朝阳区金泰国际大厦B座901"],
        hz: [[120.134434,30.299049], "杭州市拱墅区申花路99号运河财富中心7幢902室"],
        bjFt: [[116.29609,39.82719], "北京市丰台区汉威国际广场2区1号楼601"],
      },
    };
  },
  mounted() {
    this.MapInit();
  },
  components: { vbottom },
  methods: {
    handleClass(type) {
      this.map.clearMap();
      this.active = type;
      let text=""
      let latLng=[]
      if(type=='0'){
        text=this.positionList.bjCy[1]
        latLng=this.positionList.bjCy[0]
      }else if(type=='1'){
        text=this.positionList.hz[1]
        latLng=this.positionList.hz[0]
      }else if(type=='2'){
        text=this.positionList.bjFt[1]
        latLng=this.positionList.bjFt[0]
      }
      this.markerFun(text,latLng,type);
    },
    MapInit() {
      this.map = new this.$AMap.Map("container", {
        viewMode: "3D",
        center: this.positionList.bjCy[0],
        zoom: 13,
        resizeEnable: true,
      });
      this.markerFun(this.positionList.bjCy[1],this.positionList.bjCy[0],'0')
    },
    markerFun(text,latLng,type) {
      let marker = new this.$AMap.Marker({
        position: latLng,
        offset: new this.$AMap.Pixel(0, 0),
      });
      let content=''
      if(type=='0'){
        content=`<div class="markerBox" >
        <img class="qipao" src="${dizhiqipao}" alt="">
        <div class="markerContent">
          <p class="address">
            <img src="${dizhiicon}">
            <span>${text}</span>
          </p>
         <p><img src="${dianhuaicon}"><span>010-63720140</span></p>
        </div>
      </div>`
      }else{
        content=`<div class="markerBox" >
        <img class="qipao" src="${dizhiqipao}" alt="">
        <div class="markerContent">
          <p class="address">
            <img src="${dizhiicon}">
            <span>${text}</span>
          </p>  
        </div>
      </div>`
      }
      marker.setMap(this.map);
      marker.setLabel({
        offset: new this.$AMap.Pixel(-190, -70), //设置文本标注偏移量
        content:content , //设置文本标注内容
      });
      var zoom = Math.floor(Math.random() * 7) + 11;
      this.map.panTo(latLng);
      this.map.setZoomAndCenter(zoom, latLng)
    },
  },
};
</script>
<style>
  .markerBox{
  width: 397px;
  height: 100px;
  background-repeat: no-repeat;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  position: relative;
  
}

.markerBox .qipao{
    width: 100%;
  }
  .markerBox .markerContent{
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px 30px;
  }
  .markerBox .markerContent p img{
      width: 16px;
      height: 16px;
      margin-right: 10px;
  }
  .markerBox .markerContent .address{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .amap-marker-label{
    border: none;
    background-color:transparent ;
  }
</style>
<style  lang="scss" scoped>

::v-deep .amap-info-close {
  display: none;
}
::v-deep .amap-info-content .amap-info-outer {
  background: #005fff;
}
::v-deep .bottom-center .amap-info-sharp:after {
  background: #005fff;
}
.waitao {
  position: relative;
  width: 1180px;
  margin: auto;
}
.beijingtupian4 {
  background: url("../assets/banner4@2X.png");
  width: 100%;
  height: 288px;
  background-size: cover;
  position: relative;
  
}
.middle11 {
  width: 1180px;
  height: 134px;
  margin: 0 auto;
  position: relative;
}
.middle12 {
  width: 1180px;
  height: 524px;
  margin: 0 auto;
  position: relative;
}
.title1 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  margin-top: 80px;
  text-align: center;
}
.title2 {
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
}
.place .activeborder {
  border-color: #005fff;
  color: #005fff;
}

.place {
  display: flex;
  width: 563px;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 40px;
}
.place div {
  cursor: pointer;
  width: 161px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #333333;
  text-align: center;
  line-height: 40px;
}
.ditu {
  width: 100%;
  height: 560px;
  background-size: cover;
  position: relative;
  top: 23px;
}
.final {
  width: 960px;
  height: 364px;
  background: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(0, 95, 255, 0.08);
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -480px;
  margin-top: -182px;
  text-align: center;
}
.line {
  width: 40px;
  height: 4px;
  background: #005fff;
  border-radius: 2px;
  margin-top: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
}
.title3 {
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  margin-top: 44px;
}
.ztc {
  width: 150px;
  height: 48px;
  background: #005fff;
  border-radius: 24px;
  margin-top: 40px;
  margin-left: 405px;
  cursor: pointer;
}
.ztc2 {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  padding-top: 15px;
}
.title4 {
  height: 16px;
  font-size: 16px;
  color: #666666;
  line-height: 16px;
  margin-top: 20px;
  text-align: center;
}
.lanbian2 {
  width: 100%;
  height: 288px;
  background: #f7faff;
  position: absolute;
  z-index: -1;
  top: 1360px;
}
</style>