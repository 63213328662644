<template>
  <div class="culture">
    <div class="beijingtupian3"  :style="{ background: 'url('+imageList+')' }">
    </div>  
     <div class="waitao">
        <h6 class="title6">CORPORATE CULTURE</h6>
        <h6 class="title7">企业文化</h6>
        <img src ="../assets/qywhbj@2X.png" style="width:1180px;height:225px;margin:178px auto;position: absolute;">
        <img src ="../assets/mbicon.svg" style="position: absolute;top:238px;left:131px">
        <img src ="../assets/lnicon.svg" style="position: absolute;top:238px;left:529px">
        <img src ="../assets/jzgicon.svg" style="position: absolute;top:238px;left:918px">
        <h6 class="title8">目标</h6>
        <div class="line11"></div>
        <p class="intro54">提升媒体的整体运营效率与效果，成为一流的新媒体全域营销运营方案商</p>
        <div class="line14"></div>
        <h6 class="title9">理念</h6>
        <div class="line12"></div>
        <p class="intro55">精细运营，极致服务，让流量更有价值</p>
        <div class="line15"></div>
        <h6 class="title10">价值观</h6>
        <div class="line13"></div>
        <p class="intro56">诚信敬业、创新进取、</p>
        <p class="intro57">感恩互助、共同成长</p>
      </div>
    <div class="middle9">
      <img src ="../assets/jyln@2X.png" style="width:600px;height:564px;position: absolute;top:235px">
      <div class="right">
        <h6 class="title11">BUSINESS PHILOSOPHY</h6>
        <h6 class="title12">经营理念</h6>
        <div class="dian1"><div class="dian"></div></div>
        <h6 class="title13">工作环境</h6>
        <p class="intro58">创造良好的工作环境、丰富的文化活动、美味的零食下午茶，让每位员工快乐工作</p>
        <div class="dian2"><div class="dian"></div></div>
        <h6 class="title14">公平晋升</h6>
        <p class="intro59">提供公平晋升的空间与发展机会，让每位员与公司共同成长</p>
        <div class="dian3"><div class="dian"></div></div>
        <h6 class="title15">鼓励上进</h6>
        <p class="intro60">坚决淘汰不求上进者，奖励心态积极、认真努力做事者</p>
        <div class="dian4"><div class="dian"></div></div>
        <h6 class="title16">奖罚分明</h6>
        <p class="intro61">重奖时刻为客户着想的行为，严厉处理对客户造成伤害的行为</p>
        <div class="dian5"><div class="dian"></div></div>
        <h6 class="title23">合作共赢</h6>
        <p class="intro62">不断优化与员工及客户切身利益相关的制度，使员工与客户满意</p>
      </div>

    </div>
    <div class="middle10">
      <h6 class="title1">CORPORATE ACTIVITIES</h6>
      <h6 class="title2">公司活动</h6>
        <div class="hdzu">
          <div class="activity"  v-for="(item,index) in  activeList.slice(0,3)" :key="index">
            <img :src ="item.image" style="width:366px;height:140px;">
            <h6 class="title24">{{item.title}}</h6>
            <p class="intro63">{{item.describe}}</p>
            <div class="line5"></div>
            <div class="read" @click="goFirst(item._id)">阅读全文</div>
          </div>  
          <!--<div class="activity">
            <img src ="../assets/tzpx2@2X.png" style="width:366px;height:140px;">
            <h6 class="title24">天卓培训｜迎接最好的你（二）</h6>
            <p class="intro63">天卓科技新人入职培训第二场如期而至，本场小伙伴的热情与现在的体感温度形成明显对比，暖意呼之欲出…</p>
            <div class="line5"></div>
            <router-link :to="'/moreActs'" class="read">阅读全文</router-link>
          </div>
          <div class="activity">
            <img src ="../assets/tzpx1@2X.png" style="width:366px;height:140px;">
            <h6 class="title25">天卓培训｜迎接最好的你（一）</h6>
            <p class="intro64">一雨知深秋，一培训便知要迎接新员工。为了让大家更快速的了解公司，熟悉工作环境，进入工作状态，人力资源部精心筹备的新员工入职培训，如期而至…</p>
            <div class="line5"></div>
            <router-link :to="'/moreActs2'" class="read1">阅读全文</router-link>
          </div>  
          <div class="activity">
            <img src ="../assets/xwc@2X.png" style="width:366px;height:140px;">
            <h6 class="title26">天卓福利｜爱上秋天的下午茶</h6>
            <P class="intro65">工作再忙，烦恼再多，美食总能击中我们内心最柔软的地方。每一位努力工作的小伙伴，都应该得到美味的专属奖赏…</P>
            <div class="line5"></div>
            <router-link :to="'/moreActs3'" class="read2">阅读全文</router-link>
          </div>-->
        </div>
        <!-- <div @click="ondes" class="link1">查看更多活动
          <img src ="../assets/ckgdicon.svg" style="position:relative;top:3px;left:4px">
        </div> -->
     <router-link :to="'/Activities'" class="link1">查看更多活动<img src ="../assets/ckgdicon.svg" style="position:relative;top:3px;left:4px"></router-link>
    </div>
 <!-- 底部内容 -->
      <vbottom></vbottom>
  <router-view/>
  </div>
</template> 
<script>
import {enterpriseBanner,activeList} from "@/api/index" ;
import vbottom from "../components/bottom";
export default {
  data() {
    return {
      //  list:[{
      //   img:require('../assets/pxhyjr@2x.png'),
      //   title:'天卓培训｜欢迎加入',
      //   descrite:'欢迎各位伙伴加入天卓科技，我们的队伍又壮大了！为了使大家快速了解公司，熟悉工作环境，进入工作状态，人力资源部精心筹备的新员工入职培训，如期而至…',
      //   link:'/moreActs',
      // },{
      //   img:require('../assets/tzgej@2x.png'),
      //   title:'天卓福利｜感恩节，感谢你',
      //   descrite:'天卓科技始终把“家”的理念融入到公司的日常生活中。从“你”入职，“欢迎加入天卓科技大家庭”的欢迎词，到成为“我们”，一同并肩携手共进，见证公司的成长与发展…',
      //   link:'/moreActs7',
      // },{
      //   img:require('../assets/tzxyw@2x.png'),
      //   title:'天卓新业务｜拓展跨境电商市场',
      //   descrite:'我们是阿里的SKA客户，营销资源充足，经营范围涵盖服装家居类、3C数码类、电动车等多种品类的产品，业务辐射整个欧美地区，且正在向其他地区持续扩展…',
      //   link:'/moreActs8',
      // }],
      activeList:[],
      imageList: "",
    }
  },
  mounted() {
  this.getEnterpriseBanner(),
  this.getActiveList()
  },
  components: { vbottom },
  methods:{
  getEnterpriseBanner(){
    enterpriseBanner().then((data) => {
    this.imageList = JSON.parse(data.imageList)[0];
    });
  },
  getActiveList(){
    activeList().then((data) => {
       this.activeList =  data.list
       console.log(data)    
    });
  },
  goFirst(_id) {
    this.$router.push({
        path:'/moreActs',
        query:{
          _id:_id
        }
      })
   } 
  }
}
</script>   
<style scoped>
.waitao{
  position: relative;
  width: 1180px;
  margin:auto;
  top:-288px;
}
.beijingtupian3{
  /* background: url('../assets/banner3@2X.png'); */
  width:100%;
  height: 288px;
  background-size:100% !important;
  position: relative;
  background-repeat: no-repeat !important;
  min-width: 1440px;
}
.title6{
  width: 158px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #CCCCCC;
  line-height: 14px;
  position: absolute;
  top:84px;
  left:517px;
}
.title7{
  width: 120px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 30px;
  position: absolute;
  top:108px;
  left:530px;
}
.title8{
  width: 36px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 18px;
  position: absolute;
  top:239px;
  left:175px;
}
.title9{
  width: 36px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 18px;
  position: absolute;
  top:239px;
  left:572px;
}
.title10{
  width: 54px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 18px;
  position: absolute;
  top:239px;
  left:960px;
}
.line11{
  width: 40px;
  height: 4px;
  background: #FFFFFF;
  border-radius: 2px;
  position: absolute;
  top:267px;
  left:173px;
}
.line12{
  width: 40px;
  height: 4px;
  background: #FFFFFF;
  border-radius: 2px;
  position: absolute;
  top:267px;
  left:570px;
}
.line13{
  width: 40px;
  height: 4px;
  background: #FFFFFF;
  border-radius: 2px;
  position: absolute;
  top:267px;
  left:967px;
}
.intro54{
  width: 320px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 26px;
  text-align: center;
  position: absolute;
  top:291px;
  left:33px;
}
.intro55{
  width: 320px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 26px;
  text-align: center;
  position: absolute;
  top:291px;
  left:431px;
}
.intro56{
  width: 320px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 26px;
  text-align: center;
  position: absolute;
  top:291px;
  left:827px;
}
.intro57{
  width: 320px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 26px;
  text-align: center;
  position: absolute;
  top:317px;
  left:819px;
}
.middle9{
  width: 1180px;
  height:800px;
  margin: 0 auto;
  position: relative;
}
.right{
  width: 680px;
  height: 564px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px 0px rgba(0, 95, 255, 0.08);
  border-radius: 4px;
  position: absolute;
  top:195px;
  left:500px;
}
.title11{
  width: 166px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868E94;
  line-height: 14px;
  position: absolute;
  top:60px;
  left:40px;
}
.title12{
  width: 120px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  position: absolute;
  top:84px;
  left:40px;
}
.dian{
  width: 10px;
  height: 10px;
  background: #005FFF;
  border-radius: 5px;
  opacity: 0.19;
  position: absolute;
  top: -2px;
  left: -2px;
}
.dian1{
  width: 6px;
  height: 6px;
  background: #005FFF;
  border-radius: 3px;
  margin: 2px 0 0 2px;
  position: absolute;
  top:158px;
  left:40px;
}
.dian2{
  width: 6px;
  height: 6px;
  background: #005FFF;
  border-radius: 3px;
  position: absolute;
  top:232px;
  left:40px;
}
.dian3{
  width: 6px;
  height: 6px;
  background: #005FFF;
  border-radius: 3px;
  position: absolute;
  top:306px;
  left:40px;
}
.dian4{
  width: 6px;
  height: 6px;
  background: #005FFF;
  border-radius: 3px;
  position: absolute;
  top:380px;
  left:40px;
}
.dian5{
  width: 6px;
  height: 6px;
  background: #005FFF;
  border-radius: 3px;
  position: absolute;
  top:454px;
  left:40px;
}
.title13{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:154px;
  left:60px;
}
.title14{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:228px;
  left:60px;
}
.title15{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:302px;
  left:60px;
}
.title16{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:376px;
  left:60px;
}
.title23{
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:450px;
  left:60px;
}
.intro58{
  width: 590px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:182px;
  left:60px;
}
.intro59{
  width: 590px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:256px;
  left:60px;
}
.intro60{
  width: 590px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:330px;
  left:60px;
}
.intro61{
  width: 590px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:404px;
  left:60px;
}
.intro62{
  width: 590px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:478px;
  left:60px;
}
.middle10{
  width: 1180px;
  height:621px;
  margin: 0 auto;
}
.title1{
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868E94;
  line-height: 14px;
  margin-top: 80px;
  text-align: center;
}
.title2{
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 39px;
  margin-top: 10px;
  text-align: center;
}
.hdzu{
  width: 1180px;
  height:391px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.activity{
  width:366px;
  height:391px;
  box-shadow: 0px 0px 26px 0px rgba(0, 95, 255, 0.08);
  border-radius: 4px;
}
.title24{
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  margin-top:30px;
  margin-left:30px;
}
.intro63{
  width: 306px;
  height: 78px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-top:20px;
  margin-left:30px;
  text-align: justify;
  word-break: break-all;
}
.title25{
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:170px;
  left:437px;
}
.intro64{
  width: 306px;
  height: 104px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:208px;
  left:437px;
}
.title26{
  width: 252px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:170px;
  left:843px;
}
.intro65{
  width: 306px;
  height: 104px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:208px;
  left:843px;
}
.link1{
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #005FFF;
  line-height: 16px;
  margin-left: 532px;
}
.line5{
  width: 366px;
  height: 1px;
  background: #F1F1F1;
  position:absolute;
  top:342px;  
}
.read{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #005FFF;
  line-height: 16px;
  position:relative;
  top:68px;  
  left:151px;
  cursor:pointer;
}
.read1{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #005FFF;
  line-height: 16px;
  position:absolute;
  top:359px;  
  left:557px;
}
.read2{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #005FFF;
  line-height: 16px;
  position:absolute;
  top:359px;  
  left:964px;
}
.line14{
  width: 1px;
  height: 101px;
  background: #FFFFFF;
  border-radius: 1px;
  position: absolute;
  top:240px;
  left:403px;
}
.line15{
  width: 1px;
  height: 101px;
  background: #FFFFFF;
  border-radius: 1px;
  position: absolute;
  top:240px;
  left:776px;
}
</style>